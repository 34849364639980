<template>
  <v-menu v-model="visibleMenu" :close-on-content-click="false" rounded>
    <template v-slot:activator="{ on, attrs }">
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <span class="form-link" v-on="on" v-bind="attrs">
          {{ mainBtnName }}
        </span>
      </transition>
    </template>

    <v-card>
      <v-list :width="275">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <v-list-item-title>{{
              ipList
                ? "Add addresses to address list " + ipList + "?"
                : "Add addresses to address list?"
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right mt-2">
              <v-icon small v-if="processing" color="primary"
                >fas fa-spinner fa-spin</v-icon
              >
              <v-btn
                v-if="!processing"
                rounded
                text
                color="good-3"
                small
                class="text-capitalize"
                @click="confirm"
              >
                Send
              </v-btn>
              <v-btn
                v-if="!processing"
                rounded
                text
                color="bad-3"
                small
                class="text-capitalize"
                @click="reject"
              >
                Reject
              </v-btn>
              <v-btn
                v-if="!processing"
                rounded
                text
                color="tertiary"
                small
                class="text-capitalize"
                @click="visibleMenu = false"
              >
                Cancel
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { rejectCall } from "@/api/monitor/reject";
import { Errors } from "@/utils/error";
import { dataFeedsEventsCall } from "@/api/awaitingApproval";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    ipList: {
      type: String,
      default: function () {
        return "";
      },
    },
    mainBtnName: {
      type: String,
      default: function () {
        return "Confirm";
      },
    },
    width: {
      type: Number,
      default: function () {
        return 400;
      },
    },
    className: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      visibleMenu: false,
      processing: false,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
  },
  created() {},
  methods: {
    change() {
      this.changeInAddForm = "awaiting-approval";
    },
    close() {
      this.visibleMenu = false;
    },
    reject() {
      this.processing = true;
      rejectCall(this.id)
        .then(() => {
          this.$toast.open({
            message: "Event call was successfully rejected!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.processing = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.processing = false;
        });
    },
    confirm() {
      this.processing = true;
      const data = {
        eventcall_id: this.id,
      };
      dataFeedsEventsCall(data)
        .then(() => {
          this.$toast.open({
            message: "Event call was successfully confirmed!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.processing = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.processing = false;
        });
    },
  },
};
</script>
