<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        :options.sync="options"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | moment("YYYY-MM-DD HH:mm:ss") }}
        </template>
        <template v-slot:item.rules="{ item }">
          <span
            v-if="type === 'securityPolicy'"
            class="form-link"
            @click="open(item)"
          >
            {{ item.rules }}
          </span>
          <data-feeds-menu
            v-else
            :id="item.id"
            :ip-list="item.ip_list"
            :main-btn-name="item.rules"
          />
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <td
                      v-if="expandItem.value === 'created_at'"
                      width="150"
                      class="pl-3"
                    >
                      Date
                    </td>
                    <td v-if="expandItem.value === 'created_at'">
                      {{ item.created_at | moment("YYYY-MM-DD HH:mm:ss") }}
                    </td>

                    <td
                      v-if="expandItem.value === 'rules'"
                      width="150"
                      class="pl-3"
                    >
                      Rules
                    </td>
                    <td v-if="expandItem.value === 'rules'">
                      <span class="form-link" @click="open(item)">
                        {{ item.rules }}
                      </span>
                    </td>

                    <td
                      v-if="expandItem.value === 'fw'"
                      width="150"
                      class="pl-3"
                    >
                      Firewall
                    </td>
                    <td v-if="expandItem.value === 'fw'">
                      {{ item.fw }}
                    </td>

                    <td
                      v-if="expandItem.value === 'src_short'"
                      width="150"
                      class="pl-3"
                    >
                      Source short
                    </td>
                    <td v-if="expandItem.value === 'src_short'">
                      {{ item.src_short }}
                    </td>

                    <td
                      v-if="expandItem.value === 'target_short'"
                      width="150"
                      class="pl-3"
                    >
                      Destination short
                    </td>
                    <td v-if="expandItem.value === 'target_short'">
                      {{ item.target_short }}
                    </td>

                    <td
                      v-if="expandItem.value === 'tag'"
                      width="150"
                      class="pl-3"
                    >
                      Threat
                    </td>
                    <td v-if="expandItem.value === 'tag'">
                      {{ item.tag }}
                    </td>

                    <td
                      v-if="expandItem.value === 'src'"
                      width="150"
                      class="pl-3"
                    >
                      Source
                    </td>
                    <td v-if="expandItem.value === 'src'">
                      {{ item.src }}
                    </td>

                    <td
                      v-if="expandItem.value === 'target'"
                      width="150"
                      class="pl-3"
                    >
                      Destination
                    </td>
                    <td v-if="expandItem.value === 'target'">
                      {{ item.target }}
                    </td>

                    <td
                      v-if="expandItem.value === 'id'"
                      width="150"
                      class="pl-3"
                    >
                      Internal ID
                    </td>
                    <td v-if="expandItem.value === 'id'">
                      {{ item.id }}
                    </td>

                    <td
                      v-if="expandItem.value === 'syslog_id'"
                      width="150"
                      class="pl-3"
                    >
                      Syslog ID
                    </td>
                    <td v-if="expandItem.value === 'syslog_id'">
                      {{ item.syslog_id ? item.syslog_id : "-" }}
                    </td>

                    <td
                      v-if="expandItem.value === 'message_full'"
                      width="150"
                      class="pl-3"
                    >
                      Message
                    </td>
                    <td v-if="expandItem.value === 'message_full'">
                      {{ item.message_full ? item.message_full : "-" }}
                    </td>

                    <td
                      v-if="expandItem.value === 'description'"
                      width="150"
                      class="pl-3"
                    >
                      Description
                    </td>
                    <td v-if="expandItem.value === 'description'">
                      {{ item.description }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
import dataFeedsMenu from "./menu";

export default {
  components: {
    dataFeedsMenu,
  },
  props: {
    type: {
      type: String,
      default: function () {
        return "";
      },
    },
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,
      order: null,
      by: null,
      options: {},

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.options.page = newValue;
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.options.itemsPerPage = this.sizes[newValue];
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue.sortBy &&
          newValue.sortDesc &&
          newValue.sortBy.length > 0 &&
          newValue.sortDesc.length > 0
        ) {
          const order = newValue.sortDesc[0] ? "desc" : "asc";
          const by = newValue.sortBy[0];
          this.$emit("change", {
            page: this.page,
            size: this.sizes[this.size],
            order: order,
            orderBy: by,
          });
        }
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "awaitingApprovalSecPolicy";
    },
  },
};
</script>
